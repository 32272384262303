import { Badge, Colors, Dialog, Incubator, ListItem, Text, TextField, View } from 'react-native-ui-lib';
import { FirestoreConstants, navigationProps, ElementPath, PathConstants, ContainerType } from '../typings/types';
import { KeyboardAvoidingView, Platform, ScrollView } from 'react-native';
import { FlatList } from 'react-native-gesture-handler'
import React, { useEffect, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { useIsMobile } from '../MediaQueries';
import { strings } from '../localization/localization.web';
import { DocumentData, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, limit, onSnapshot, orderBy, query, setDoc } from 'firebase/firestore';
import { ButtonPrimary, IconButton } from './Buttons';
import { styles } from '../styles';
import { getGroupStatementsQuery, useUserState } from '../firebaseWrapper/firebaseWrapper';
import { MaterialIcons } from '@expo/vector-icons';
import make_uuid from 'react-uuid';
import { FloatingActionButton } from './FloatingActionButton';
import { LazyList } from './FeedList';
import { TopicBadge } from './Badges';
import { alert } from './Alert';
import { Group } from '../dataModel';
import * as Clipboard from 'expo-clipboard';

export function GroupScreen({
  navigation,
  route,
}: navigationProps): JSX.Element {
  const parentInfo: ElementPath = {
    loc: PathConstants.GROUP,
    id: route.params.id,
    type: ContainerType.GROUP,
    groupId: route.params.id
  }
  const [transitionFinished, setTransitionFinished] = useState(false);
  const isMobile = useIsMobile();
  const [groupData, setGroupData] = React.useState<DocumentData>();
  const [topics, setTopics] = React.useState([]);
  const [members, setMembers] = React.useState([]);
  const [memberLoggedIn, setMemberLoggedIn] = React.useState(false);
  const [showLoginForm, setShowLoginForm] = React.useState(false);
  const [memberName, setMemberName] = React.useState('');
  const [memberPassword, setMemberPassword] = React.useState('');
  const [loggedInMemberId, setLoggedInMemberId] = React.useState('');
  const [user, loading] = useUserState();
  const [adminLoggedIn, setAdminLoggedIn] = React.useState(false);
  const [showAddMemberModal, setShowAddMemberModal] = React.useState(false);
  const [showChangeMemberModal, setShowChangeMemberModal] = React.useState(false);
  const [memberSubjectToChange, setMemberSubjectToChange] = React.useState('');
  const [newMemberName, setNewMemberName] = React.useState('');
  const [newMemberPassword, setNewMemberPassword] = React.useState('');
  const [showMemberDialog, setShowMemberDialog] = React.useState(false);
  const [topicsLoading, setTopicsLoading] = React.useState(true);
  const [groupLinkCopied, setGroupLinkCopied] = React.useState(false);
  const topicsReference = collection(getFirestore(), FirestoreConstants.GROUPS, route.params.id, FirestoreConstants.TOPICS);
  const topicsQuery = query(topicsReference,
    orderBy('creationTime', 'desc'),
    limit(10)
  );

  useFocusEffect(() => {
    if (!transitionFinished) {
      setTransitionFinished(true);
    }
  });

  useEffect(() => {
    const documentReference = doc(getFirestore(), FirestoreConstants.GROUPS, route.params.id);
    getDoc(documentReference).then((documentSnapshot) => {
      if (documentSnapshot.exists()) {
        // console.log('Group data:', documentSnapshot.data());
        setGroupData({ ...documentSnapshot.data(), id: documentSnapshot.id });
      } else {
        console.log('No such document!');
      }
    }).catch((error) => {
      console.log('Error getting document:', error);
    });
  }, []);

  useEffect(() => {
    getDocs(topicsQuery).then((querySnapshot) => {
      setTopics(
        querySnapshot.docs.map((document) => ({ ...document.data(), id: document.id }))
      )
      setTopicsLoading(false);
    }
    );
  }, []);

  onSnapshot(topicsQuery, (snapshot) => {
    if (!topicsLoading) {
      setTopicsLoading(true);
      if (snapshot.docs.length !== topics.length) {
        setTopics(
          snapshot.docs.map((document) => ({ ...document.data(), id: document.id }))
        )
        setTopicsLoading(false);
      }
    }
  });

  useEffect(() => {
    const membersReference = collection(getFirestore(), FirestoreConstants.GROUPS, route.params.id, FirestoreConstants.MEMBERS);
    const membersQuery = query(membersReference);
    getDocs(membersQuery).then((querySnapshot) => {
      setMembers(
        querySnapshot.docs.map((document) => ({ ...document.data(), id: document.id }))
      )
    }
    );
  }, []);

  useEffect(() => {
    if (groupData && user) {
      setAdminLoggedIn(groupData.admin === user.uid);
    }
  }, [groupData, user]);

  useEffect(() => {
    setShowLoginForm(!memberLoggedIn && !adminLoggedIn);
  }, [memberLoggedIn, adminLoggedIn]);

  const loginMember = () => {
    const member = members.find(member => member.name === memberName);
    if (member && member.password === memberPassword) {
      setMemberLoggedIn(true);
      setLoggedInMemberId(member.id);
      setShowLoginForm(false);
    } else {
      alert(strings.member_login_failed);
    }
  };

  const logoutMember = () => {
    setMemberLoggedIn(false);
    setMemberName('');
    setMemberPassword('');
    setShowLoginForm(true);
  };

  function setMember() {
    if (showChangeMemberModal) {
      if (newMemberName && newMemberPassword && groupData && adminLoggedIn && memberSubjectToChange) {
        const changedMember = { name: newMemberName, password: newMemberPassword };
        setDoc(doc(getFirestore(), FirestoreConstants.GROUPS, groupData.id, FirestoreConstants.MEMBERS, memberSubjectToChange),
          changedMember
        ).then(() => {
          const changedMemberindex = members.findIndex(member => member.id === memberSubjectToChange);
          members[changedMemberindex] = { ...changedMember, id: memberSubjectToChange };
          setMembers([...members]);
          setNewMemberName('');
          setNewMemberPassword('');
          setShowChangeMemberModal(false);
          setMemberSubjectToChange('');
        }
        ).catch((error) => {
          console.error("Error adding document: ", error);
        });
      } else {
        alert(strings.add_member_hint);
      }
    } else {
      const duplicateMemberExists = members.find(member => member.name === newMemberName) !== undefined;
      if (newMemberName && groupData && adminLoggedIn && !duplicateMemberExists) {
        const newMember = { name: newMemberName, password: newMemberPassword || Group.generateMemberPassword() };
        const newMemberId = make_uuid();
        setDoc(doc(getFirestore(), FirestoreConstants.GROUPS, groupData.id, FirestoreConstants.MEMBERS, newMemberId),
          newMember
        ).then(() => {
          setMembers([...members, { ...newMember, id: newMemberId }]);
          setNewMemberName('');
          setNewMemberPassword('');
          setShowAddMemberModal(false);
          setShowChangeMemberModal(false);
          setMemberSubjectToChange('');
        }
        ).catch((error) => {
          console.error("Error adding document: ", error);
        });
      } else {
        alert(strings.add_member_hint);
      }
    }
  }

  const navigateToTopic = (topicId: string) => {
    navigation.push('topic', { id: topicId, group: groupData.id, member: loggedInMemberId });
  }

  const handleChangeMember = (memberId: string, memberName: string, memberPassword: string) => {
    setShowChangeMemberModal(true);
    setShowMemberDialog(false);
    setMemberSubjectToChange(memberId);
    setNewMemberName(memberName);
    setNewMemberPassword(memberPassword);
  }

  const handleRemoveMember = (memberId: string) => {
    let confirmed = false;
    alert(strings.remove_member, strings.remove_member_hint, strings.cancel, null, strings.remove_member, () => { confirmed = true });
    if (groupData && adminLoggedIn && confirmed) {
      const memberIndex = members.findIndex(member => member.id === memberId);
      members.splice(memberIndex, 1);
      setMembers([...members]);
      deleteDoc(doc(getFirestore(), FirestoreConstants.GROUPS, groupData.id, FirestoreConstants.MEMBERS, memberId))
        .then(() => {
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    }
  }

  const handleShareGroupLink = () => {
    const group = new Group(groupData.id);
    Clipboard.setStringAsync(group.getLink())
      .then(() => {
        setGroupLinkCopied(true);
        alert(strings.group_link_copied, strings.group_link_copied_hint);
      });
  }

  const handleCopyMemberCredentials = (memberName: string, memberPassword: string) => {
    if (adminLoggedIn) {
      Clipboard.setStringAsync(`${strings.member_name}: ${memberName}\n${strings.password}: ${memberPassword}`)
        .then(() =>
          alert(strings.member_credentials_copied, strings.member_credentials_copied_hint)
        );
    }
  }

  return (
    <View
      flex
      style={{
        backgroundColor: Colors.primaryBG
      }}
    >
      <View flex
        style={{
          backgroundColor: isMobile ? 'transparent' : Colors.secondaryBG,
          borderRadius: isMobile ? 0 : 20,
          marginHorizontal: isMobile ? 0 : 5,
          marginVertical: 5,
          overflow: 'hidden',
        }}
      >
        {showLoginForm && groupData ?
          <View flex style={{ marginHorizontal: 10, marginVertical: 5, marginBottom: 0, padding: isMobile ? 0 : 20 }}>
            <Text marginB-8 text40 style={{ color: Colors.textPrimary }}>
              {groupData.name}
            </Text>
            <View flex centerV margin-10 style={{
              maxWidth: 1000,
              alignSelf: 'center',
              backgroundColor: isMobile ? 'transparent' : Colors.secondaryBG,
            }}>
              <Text h2 marginV-10 marginH-5 textPrimary>
                {strings.login}
              </Text>
              <TextField
                style={styles.inputContainer}
                placeholder={strings.member_name}
                placeholderTextColor={Colors.textSecondary}
                onChangeText={newMemberName => setMemberName(newMemberName)}
                value={memberName}
              />
              <TextField
                style={styles.inputContainer}
                secureTextEntry={true}
                placeholder={strings.password}
                placeholderTextColor={Colors.textSecondary}
                onChangeText={newPassword => setMemberPassword(newPassword)}
              />
              <View marginT-10 marginB-0>
                <ButtonPrimary label={strings.login} onPress={() => loginMember()} />
              </View>
              <View row centerV marginV-5 marginH-5>
                <Text textPrimary>
                  {strings.not_a_group_member_hint}
                </Text>
              </View>
            </View>
          </View>
          : null
        }
        {groupData && (memberLoggedIn || adminLoggedIn) ?
          <View flex style={{ marginHorizontal: isMobile ? 0 : 10, marginVertical: 5, marginBottom: 0 }}>
            <View flex style={{ backgroundColor: 'transparent', borderRadius: 20, paddingVertical: isMobile ? 0 : 20 }}>
              <View style={{ paddingHorizontal: isMobile ? 15 : 25 }}>
                <Text marginB-8 text40 style={{ color: Colors.textPrimary }}>
                  {groupData.name}
                </Text>
                <View flex row style={{ flexWrap: 'wrap', alignItems: 'flex-start', gap: 8 }} >
                  <Badge
                    customElement={<MaterialIcons name="people" size={16} color={Colors.iconSecondary} />}
                    label={members.length + " " + strings.members} size={20} backgroundColor={Colors.quaternaryBG} labelStyle={{ color: Colors.textSecondary }}
                    onPress={() => setShowMemberDialog(true)}
                  />
                  {adminLoggedIn ?
                    <Badge
                      customElement={<MaterialIcons name="add" size={16} color={Colors.iconSecondary} />}
                      label={strings.add_member} size={20} backgroundColor={Colors.quaternaryBG} labelStyle={{ color: Colors.textSecondary }}
                      onPress={() => setShowAddMemberModal(true)} />
                    : null
                  }
                  {memberLoggedIn ?
                    <Badge
                      customElement={<MaterialIcons name="logout" size={16} color={Colors.iconSecondary} />}
                      label={strings.logout} size={20} backgroundColor={Colors.quaternaryBG} labelStyle={{ color: Colors.textSecondary }} onPress={() => logoutMember()} />
                    : null
                  }
                  {adminLoggedIn ?
                    <Badge
                      label={strings.you_are_group_admin}
                      customElement={<MaterialIcons name="admin-panel-settings" size={16} color={Colors.iconSecondary} />}
                      size={20} backgroundColor={Colors.quaternaryBG} labelStyle={{ color: Colors.textSecondary }} />
                    : null
                  }
                  {adminLoggedIn ?
                    <Badge
                      label={groupLinkCopied ? strings.group_link_copied : strings.share_group_link}
                      customElement={<MaterialIcons name={groupLinkCopied ? "check" : "share"} size={16} color={groupLinkCopied ? Colors.textAccentTertiary : Colors.iconSecondary} />}
                      size={20}
                      backgroundColor={Colors.quaternaryBG}
                      labelStyle={{ color: groupLinkCopied ? Colors.textAccentTertiary : Colors.textSecondary }}
                      onPress={() => handleShareGroupLink()}
                    />
                    : null
                  }
                </View>
                <Text marginT-10 marginB-10 textPrimary text60>
                  {strings.topics}
                </Text>
              </View>
              {topics ?
                <View marginB-15 style={{ marginLeft: isMobile ? 15 : 25 }}>
                  <FlatList
                    data={topics}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    renderItem={({ item }) =>
                      <TopicBadge topic={item.name} onPress={() => navigateToTopic(item.id)} />
                    }
                    ItemSeparatorComponent={() =>
                      <View padding-2 />
                    }
                  />
                </View>
                : null
              }
              {groupData ?
                <LazyList
                  navigation={navigation}
                  route={route}
                  query={getGroupStatementsQuery(groupData.id)}
                  location={PathConstants.GROUP}
                  groupId={groupData.id}
                  memberId={loggedInMemberId}
                  groupAdminLoggedIn={adminLoggedIn}
                  header={strings.all_statements}
                />
                : null}
            </View>
            <Dialog visible={showAddMemberModal || showChangeMemberModal}
              onDismiss={() => {
                setShowAddMemberModal(false);
                setShowChangeMemberModal(false);
                setMemberSubjectToChange('');
                setNewMemberName('');
                setNewMemberPassword('');
              }}
              renderPannableHeader={(props: any) => <View></View>}>
              <KeyboardAvoidingView
                behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
                <View padding-15 style={{ borderRadius: 18, backgroundColor: Colors.secondaryBG }}>
                  <View style={{ padding: 20 }}>
                    <Text h3 marginV-10 marginH-5 textPrimary>
                      {showChangeMemberModal ? strings.change_member_in : strings.add_member_to} {groupData.name}
                    </Text>
                    <TextField
                      style={styles.inputContainer}
                      placeholder={strings.member_name}
                      placeholderTextColor={Colors.textSecondary}
                      onChangeText={newMemberName => setNewMemberName(newMemberName)}
                      value={newMemberName}
                    />
                    <TextField
                      style={styles.inputContainer}
                      secureTextEntry={true}
                      placeholder={strings.password}
                      placeholderTextColor={Colors.textSecondary}
                      onChangeText={newPassword => setNewMemberPassword(newPassword)}
                      value={newMemberPassword}
                    />
                    {showAddMemberModal ?
                      <View paddingL-5>
                        <Text color={Colors.inactiveText}>
                          {strings.generated_password_hint}
                        </Text>
                      </View>
                      :
                      null
                    }
                    <View marginT-10 marginB-0>
                      <ButtonPrimary label={showChangeMemberModal ? strings.change_member : strings.add_member}
                        onPress={() => setMember()}
                      />
                    </View>
                  </View>
                </View>
              </KeyboardAvoidingView>
            </Dialog>
            <Incubator.Dialog visible={showMemberDialog}
              onDismiss={() => {
                setShowMemberDialog(false);
              }}
              width={"100%"}
              height={"100%"}
              bottom
              containerStyle={{ backgroundColor: 'transparent', borderTopLeftRadius: 20, borderTopRightRadius: 20, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, margin: 0 }}
            >
              <ScrollView showsVerticalScrollIndicator={false} padding-15 style={{ backgroundColor: Colors.secondaryBG }}>
                <View style={{ padding: 20 }}>
                  <View flex row centerV marginB-20 marginT-10>
                    <Text h3 textPrimary marginR-10>
                      {strings.members}
                    </Text>
                    <View>
                      <ButtonPrimary label={strings.add_member} onPress={() => {
                        setShowAddMemberModal(true)
                        setShowMemberDialog(false)
                      }}
                      />
                    </View>
                  </View>
                  <FlatList
                    data={members}
                    showsVerticalScrollIndicator={false}
                    numColumns={1}
                    renderItem={({ item }) => (
                      <ListItem height={45} onPress={() => adminLoggedIn ? handleCopyMemberCredentials(item.name, item.password) : null}>
                        <ListItem.Part left>
                          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <MaterialIcons name="person" size={30} color={Colors.iconSecondary} />
                            <View paddingL-10 style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                              <Text>{item.name}</Text>
                              {adminLoggedIn ?
                                <Text>{strings.password}: {item.password}</Text>
                                : null}
                            </View>
                          </View>
                        </ListItem.Part>
                        {adminLoggedIn && (
                          <ListItem.Part middle>
                          </ListItem.Part>
                        )}
                        {adminLoggedIn ?
                          <ListItem.Part right>
                            <View paddingR-10>
                              <IconButton
                                onPress={() => handleCopyMemberCredentials(item.name, item.password)}
                                icon={<MaterialIcons name="content-copy" size={16} color={Colors.iconSecondary} />}
                              />
                            </View>
                            <View paddingR-10>
                              <IconButton
                                onPress={() => handleChangeMember(item.id, item.name, item.password)}
                                icon={<MaterialIcons name="edit" size={16} color={Colors.iconSecondary} />}
                              />
                            </View>
                            <IconButton
                              onPress={() => handleRemoveMember(item.id)}
                              icon={<MaterialIcons name="person-remove" size={16} color={Colors.iconSecondary} />}
                            />
                          </ListItem.Part>
                          : null
                        }
                      </ListItem>
                    )}
                  />
                </View>
              </ScrollView>
            </Incubator.Dialog>
          </View>
          : null
        }
      </View >
      {adminLoggedIn || memberLoggedIn ?
        <FloatingActionButton
          navigation={navigation}
          parentInfo={parentInfo}
          possibleTypes={[...adminLoggedIn ? [ContainerType.CATEGORY] : [], ContainerType.STATEMENT]}
          memberId={loggedInMemberId}
        />
        : null
      }
    </View >
  );
}